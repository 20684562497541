var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "code-stats-wrapper" }, [
    _vm.basicInfo && !_vm.hideMeta
      ? _c("div", { staticClass: "user-meta" }, [
          _c("div", { staticClass: "user-info-wrap" }, [
            _c("p", { staticClass: "username" }, [
              _vm._v(_vm._s(_vm.basicInfo.username)),
            ]),
            _c("p", { staticClass: "user-level" }, [
              _vm._v(_vm._s(_vm.basicInfo.level)),
            ]),
          ]),
          _c("div", { staticClass: "total-xp-wrap" }, [
            _c("p", { staticClass: "total-xp" }, [
              _vm._v(_vm._s(_vm._f("formatTotalXp")(_vm.basicInfo.totalXp))),
            ]),
            _c("p", { staticClass: "new-xp" }, [
              _vm._v(_vm._s(_vm._f("formatNewXp")(_vm.basicInfo.newXp))),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", {
      staticClass: "xp-heat-chart",
      attrs: { id: `xp-history-${_vm.chartId}` },
    }),
    _c("div", {
      staticClass: "language-pie-chart",
      attrs: { id: `languages-${_vm.chartId}` },
    }),
    _c("div", {
      staticClass: "machine-percentage-chart",
      attrs: { id: `machines-${_vm.chartId}` },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }